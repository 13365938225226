import type { ISbStoriesParams } from '@storyblok/react';

import { useStoryblokState, getStoryblokApi, StoryblokComponent } from '@storyblok/react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
// import getConfig from 'next/config';
import { useRouter } from 'next/router';
import NextNprogress from 'nextjs-progressbar';
import Custom404 from './404';

// const { serverRuntimeConfig } = getConfig();

export default function Page({ story, locale, extra, preview }) {
  const router = useRouter();
  story = useStoryblokState(story, {
    language: locale,
  });

  if (router.isFallback)
    return (
      <NextNprogress
        color="#2D445D"
        startPosition={0.3}
        stopDelayMs={200}
        height={3}
        showOnShallow={true}
      />
    );

  if (!story) return <Custom404 />;

  return (
    <StoryblokComponent
      blok={story.content}
      page={{
        slug: story.slug,
        full_slug: story.full_slug,
        realPath: story.path, // storyblok real path if it exists
        name: story.name,
        locale,
        extra,
        preview,
      }}
    />
  );
}

export async function getServerSideProps({ params, locale, preview }) {
  const slug: string = params?.slug?.join('/') || 'home';

  if (
    !preview &&
    (params?.slug?.[0] === 'private-global-components' || params?.slug?.[0] === 'storyblok')
  ) {
    return { notFound: true };
  }

  // TODO: Just make this request if the slug is one of the pages who requires skills or roles (?) to avoid making this request for all pages
  const storyblokApi = getStoryblokApi();

  try {
    const {
      data: { story, cv },
    } = await storyblokApi.get(`cdn/stories/${slug}`, {
      version: preview ? 'draft' : 'published',
      // TODO: https://www.storyblok.com/tp/using-relationship-resolving-to-include-other-content-entries#resolving-more-than-50-relations
      resolve_relations: ['globalComponent.story'],
      language: locale,
    } satisfies ISbStoriesParams);

    // TODO: Fetch Skills / Roles Links from skills his own components
    const hireDevelopersStories = await storyblokApi.getAll('cdn/stories', {
      // TODO: Keep in mind this limit of stories by request - https://www.storyblok.com/docs/api/content-delivery/v2#topics/pagination
      starts_with: 'hire-developers/',
      version: preview ? 'draft' : 'published',
      // TODO: Temporal fix until we talk with the storyblok team
      cv,
    });

    const skills =
      hireDevelopersStories
        ?.filter(
          ({ full_slug }) =>
            full_slug.startsWith('hire-developers/skills/') &&
            full_slug !== 'hire-developers/skills/',
        )
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        .map(({ name, content, slug, full_slug }) => ({
          slug,
          full_slug,
          name,
          icon: content?.icon?.filename || null,
        })) || [];
    const roles = hireDevelopersStories.reduce((acc, { uuid, name, content, slug, full_slug }) => {
      if (!full_slug.startsWith('hire-developers/roles/') || full_slug === 'hire-developers/roles/')
        return acc;
      return {
        ...acc,
        [uuid]: {
          name,
          slug,
          full_slug,
          icon: content?.icon?.filename || null,
        },
      };
    }, {});

    const jobStories = await storyblokApi.getAll(`cdn/stories`, {
      starts_with: 'job-descriptions/',
      version: preview ? 'draft' : 'published',
      cv,
    });
    const jobs = jobStories.reduce((acc, { uuid, name, content, slug, full_slug }) => {
      if (!full_slug.startsWith('job-descriptions/') || full_slug === 'job-descriptions/')
        return acc;
      return {
        ...acc,
        [uuid]: {
          name,
          slug,
          full_slug,
          icon: content?.icon?.filename || null,
        },
      };
    }, {});

    const footer = await storyblokApi.getStory(`private-global-components/footers/footer`, {
      version: preview ? 'draft' : 'published',
      cv,
      // https://www.storyblok.com/faq/link-object-history#resolve_links-url
      resolve_links: 'url',
    });

    return {
      props: {
        ...(await serverSideTranslations(locale)),
        story: story || false,
        key: story?.id || false,
        preview: !!preview,
        extra: {
          skills,
          roles,
          jobs,
          footer,
        },
      },
      // revalidate: 3600,
    };
  } catch (error) {
    // TODO: Throw and error who redirects to 404 to avoid caching pages with Errors (?)
    // if (error) throw new Error(`No data found for slug: ${slug}`);
    return { notFound: true };
  }
}

/*
export async function getStaticPaths() {
  if (!serverRuntimeConfig.storyblokApiToken)
    return {
      paths: [],
      fallback: false,
    };

  const storyblokApi = getStoryblokApi();
  const links = await storyblokApi.getAll('cdn/links', {
    version: 'published',
  });

  const paths = [];
  links.forEach((linkKey) => {
    if (linkKey.is_folder) {
      return;
    }

    const slug = linkKey.slug;
    const splitSlug = slug.split('/');

    if (splitSlug[0] === 'private-global-components') {
      return;
    }

    paths.push({ params: { slug: splitSlug } });
  });

  return {
    paths: paths,
    fallback: true,
  };
}
*/
